<template>
  <main class="main page-inside text-page faq-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="left">
            <div class="stroke-text">
              FAQ
            </div>
            <div class="title big">
              FAQ
            </div>
            <div class="title small">
              Common Frequently Asked Questions
            </div>
          </div>
          <div class="right">
            <div class="item" v-for="(item, index) in faq" :key="item.id">
              <div :class="['title small', {'active': isActivePanel(index)}]" @click="togglePanel(index)">{{item.question}}</div>
              <div class="desc regular answer" v-if="isActivePanel(index)" v-html="item.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Faq',
  props: ['categories'],
  components: {
  },
  data: function() {
    return {
      faq: [],
      activePanels: [],
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
          this.faq = res.data.payload;
      })
      .catch(() => {
        
      })
  },
  methods: {
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>