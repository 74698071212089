<template>
  <div class="modal buy-modal">
    <div class="overlay"  @click="$parent.closeBuyModal"></div>
    <div class="wrapper">
      <div class="close" @click="$parent.closeBuyModal">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_168_1950)">
          <path d="M18 6L6 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_168_1950">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title">Корзина</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div>Наименование</div>
                      </th>
                      <th>
                        <div>Цена</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in cartContents" :key="item.id">
                      <td>
                        <div class="flex">
                          <div @click="removeFromCart(item)" class="bin">
                            <img src="./../img/bin.svg" class="img"/>
                          </div>
                          <div class="preview">
                            <img v-if="item.item.product_type == 'image'" :src="$parent.imgDomain + item.item.doc_url" class="img"/>
                            <div class="doc-img" v-else>
                              <img src='./../img/doc.svg' class="img"/>
                            </div>
                            
                          </div>
                          <div class="desc">{{item.item.title}}</div>
                        </div>
                      </td>
                      <td>
                        {{item.item.price}} {{$parent.currency}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="desc clear-cart" @click="$parent.crealCart">Очистить корзину</div>
                      </td>
                      <td><div class="total-desc">Всего:</div> {{totalPrice}} {{$parent.currency}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="bottom">
                <div class="button" @click="$parent.closeBuyModal">Продолжить покупки</div>
                <button class="button blue" @click="buyProduct">Купить</button>
              </div>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyModal',
  props: ['cartContents', 'totalPrice'],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    
  },
  computed: {
    
  },
  methods: {
    buyProduct() {
      this.$parent.buyProduct();
    },
    removeFromCart(item) {
      this.$emit('removeFromCart', item)
    }
  }
}
</script>