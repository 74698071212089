<template>
  <div class="modal withdraw-modal">
    <div class="overlay"  @click="$parent.closeWithdrawModal"></div>
    <ValidationObserver v-slot="{ invalid, pristine, submitting, handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(submit)" class="wrapper">
        <img class="close" @click="$parent.closeWithdrawModal" src="./../img/close.svg"/>
        <div class="container">
          <div class="form-wrapper">
            <div class="title small">Withdraw</div>
            <div class="desc">Available funds: {{ $parent.currency }} {{ $parent.userBalance }}</div>
            <ValidationProvider name="number" v-slot="{ invalid, dirty }" rules="required|numeric" slim>
              <div class="input-container">
                <input type="number" :class="{error: invalid && dirty}" placeholder="Enter amount" v-model="amount"/>
              </div>
            </ValidationProvider>
            <div class="buttons">
              <button class="button" @click="$parent.closeWithdrawModal">
                <span>Cancel</span>
              </button>
              <button type="submit" :disabled="invalid || pristine || submitting" class="button blue" @click="submit">
                <span>Submit</span>
              </button>
              <transition name="fade">
                <div class="desc red" v-if="error">{{ error }}</div>
              </transition>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: 'WithdrawModal',
  props: [],
  components: {
    ValidationObserver, ValidationProvider
  },
  data: function() {
    return {
      amount: '',
      error: ''
    }
  },
  methods: {
    submit() {
      this.$http.post(process.env.VUE_APP_API + 'withdraw/request', {
        amount: this.amount
      }).then((res) => {
        const data = res.data;
        if (data.status === 'NEEDS_VERIFICATION') {
          this.$parent.closeWithdrawModal();
          this.$parent.openVerifyModal();
        } else if (data.status === 'ERROR') {
          if (data.message) {
            this.error = data.message;
          }
        } else if (data.status === 'OK') {
          this.$parent.setWithdrawAmount(this.amount);
          this.$parent.openWithdrawConfirmModal();
        }
      }).catch((res) => {
        this.error = res.response.data.message
        if (res.response.status === 401) {
          this.openSignInModal();
        }
      })
    }
  }
}
</script>