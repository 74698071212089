<template>
  <main class="main product-list-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="breadcrumbs" v-if="activeCategory">
          <div v-if="$parent.categories && activeType == 'doc'" class="desc link" @click="$emit('chooseType', $parent.categories[1])">
            {{ $parent.categories[1].title }}
          </div>
          <div v-if="categories && activeType == 'image'" class="desc link" @click="$emit('chooseType', $parent.categories[0])">
            {{ $parent.categories[0].title }}
          </div>
          <div class="desc" v-if="activeCategory">
            Home
          </div>
          <div class="desc" v-if="activeCategory">/</div>
          <div class="desc" v-if="activeCategory">
            Images
          </div>
          <div class="desc" v-if="activeCategory">/</div>
          <div class="desc link" v-if="activeCategory">{{ activeCategory.replace(/_/g, " ") }}</div>
        </div>
        <div class="wrapper first">
          <div class="stroke-text">
            {{ activeCategory.replace(/_/g, " ") }}
          </div>
          <div class="left">
            <div class="input-container input-container-filter">
              <div class="input-type">
                <img src='./../img/imgIcon.svg' class="img"/>
                <div class="desc">Images</div>
              </div>
              <input type="text" placeholder="Enter request" @input="filterProducts" v-model="search"/>
              <div class="more-filters" @click="$parent.openFilterModal()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_96_715)">
                  <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 4V8" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 12V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 4V14" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 18V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 4V5" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 9V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_96_715">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              <button class="button blue" @click="filterProducts">
                <img src='./../img/glass.svg' class="img"/>
              </button>
            </div>
            <div :class="{'no-tags': !activeTag.length}" class="tags">
              <div v-for="tag in activeTag" class="button tags__item" :key="tag">
                {{ tag }}
              </div>
            </div>
            <div class="reset-filters desc" @click="resetFilters">
              <span>
                <b>Reset all fillters</b>
              </span>
            </div>
          </div>
          <div class="right">
            <div class="select-container">
              <select v-model="sort" @change="filterProducts">
                <option selected hidden disabled value="">Sort by date</option>
                <option value="desc">Newest</option>
                <option value="asc">Oldest</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section class="section product-list-section2">
        <div class="wrapper">
          <div class="top" v-if="productList && !productList.length">
            <div class="desc">No products found</div>
          </div>
          <!-- <div class="top" v-if="productList && productList.length">
            <div class="desc">Found <b>{{productList.length}}</b> items by your request</div>
          </div> -->
          <transition name="fade">
            <div class="list products" v-if="productList && productList.length">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard
                  :error="$parent.error"
                  :item="item" 
                  :sliderIsClickble="true"
                  :currency="currency" 
                  :categories="categoryOptions"
                  :addToCartChosenItem="addToCartChosenItem"
                  @goToProduct="goToProduct" 
                  @openBuyModal="openBuyModal" 
                  @addToCart="addToCart"
                  @openReadyModal="openReadyModal"
                />
              </div>
              <div class="title no-products" v-if="!productList.length">
                No products!
              </div>
              <div 
                v-if="productList.length != productListTotal"
                class="desc load-more"
                @click="loadMore"
              >
                <b>Load more</b>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_464_999)">
                  <path d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5C18 5.55228 18.4477 6 19 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_464_999">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';

export default {
  name: 'ProductList',
  props: ['currency', 'filter', 'appActiveType','categories', 'addToCartChosenItem'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      sort: 'desc',
      category: [],
      model: [],
      productList: [],
      activeCategory: '',
      activeTag: '',
      activeType: '',
      categoryOptions: [],
      search: '',
      activeCategoryId: '',
      productsLimit: 12,
      productListTotal: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    let filterIsSet = false;
    if (this.$route.params) {
      if (this.$route.params == 'All') {
        this.activeCategory = '';
      } else {
        this.activeCategory = this.$route.params.type;
      }
      filterIsSet = true;
    } else {
      this.activeCategory = '';
    }

    if (this.$route.query['filter[tag]']) {
      this.activeTag = this.$route.query['filter[tag]'];
      filterIsSet = true;

      let newArray = [];
      let wordsArray = this.activeTag.split(',');
      wordsArray.forEach(word => {
          newArray.push(word.trim());
      });
      this.activeTag = newArray;
      
    } else {
      this.activeTag = '';
    }

    this.checkType();

    if (filterIsSet) {
      this.filterProducts('type');
    }
  },

	watch: {
    categories() {
      if (this.$route.params) {
        if (this.$route.params == 'All') {
          this.activeCategory = '';
        } else {
          this.activeCategory = this.$route.params.type;
        }
      }
      if (this.$route.params.type) {
        this.filterProducts('type');
      }
    },
    filter: function (newArr) {
      this.filterProducts(newArr);
    },
    currency: function() {
      // this.filterProducts();
    },
    $route() {
      this.checkType();

      if (!this.$route.query['filter[category]']) {
        this.activeCategory = ''
      }

      if (!this.$route.query['filter[tag]']) {
        this.activeTag = ''
      }
    },
    appActiveType: function() {
      this.productList = [];
      this.activeCategory = '';
    },
	},
  computed: {
    activeCategories() {
      let categoryIds = this.activeCategory;

      if (categoryIds && this.categoryOptions) {
        if (typeof categoryIds === 'string' ) {
          categoryIds = categoryIds.split(',').map((id) => parseInt(id))
        } else if (Number.isInteger(categoryIds)) {
          categoryIds = [categoryIds];
        }

        return this.categoryOptions.filter(({id}) => categoryIds.includes(id));
      }

      return [];
    }
  },
  methods: {
    openReadyModal(item) {
      this.$emit('openReadyModal', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    loadMore() {
      this.productsLimit = this.productsLimit + 12;
      this.filterProducts('type');
    },
    resetFilters() {
      const currentPath = this.$route.path;
      this.activeTag = '';
      
      this.$router.replace({ path: currentPath, query: '' });
      this.filterProducts('type');
    },
    checkType() {
      if (this.$route.params.type === 'images') {
        this.activeType = 'image';
      } else if (this.$route.params.type === 'texts') {
        this.activeType = 'doc';
      }
    },
    setCategory(id) {
      this.activeCategory = id;
      const currentPath = this.$route.path;
      const currentQuery = this.$route.query;
      const newQuery = { ...currentQuery, 'filter[category]': id };
      this.$router.replace({ path: currentPath, query: newQuery });
      this.filterProducts();
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
    filterProducts(params) {
      if (params != 'type') {
        if (params && params.activeCategories && Array.isArray(params.activeCategories)) {
          const activeCategories = params.activeCategories;
          this.activeCategory = activeCategories.length ? activeCategories.map(obj => obj.id) : '';
        }

        if (params && params.activeTags && Array.isArray(params.activeTags)) {
          const activeTags = params.activeTags;
          this.activeTag = activeTags.length ? activeTags.map(obj => obj.name) : '';
        }
      }

			let activeCategory;
			let activeTag;
      // if (paramCategories && paramCategories.length) {
      //   const paramIdies = paramCategories.map(obj => obj.id);
      //   this.activeCategory = paramIdies;
      // }

      if (params == 'type') {
        if (this.$route.params.type == 'All') {
          this.activeCategoryId = '';
        } else {
          if (this.categories && this.categories.length) {
          let activeCatId = this.categories.find(obj => obj.title.toLowerCase().replace(/\s+/g, '_') === this.activeCategory).id;
          this.activeCategoryId = activeCatId;
        } else {
          return;
        }
        }
        
      }
			if (this.activeCategory) {
        activeCategory = '&categories_id=' + this.activeCategoryId;
      } else {
        activeCategory = ''
      }

      if (this.activeTag) {
        activeTag = '&tags=' + this.activeTag;
      } else {
        activeTag = ''
      }

      let activeSort;
      if (this.sort) {
        activeSort = '&sort=id~' + this.sort;
      } else {
        activeSort = ''
      }
      let searchText;
      if (this.search) {
        searchText = '&search=' + this.search;
      } else {
        searchText = ''
      }
			this.$http.get(process.env.VUE_APP_API + 'products/catalog?product_type=image&page=1&limit=' + this.productsLimit + activeCategory + activeTag + activeSort + searchText )
			.then((res) => {
        this.productListTotal = res.data.meta.total;
				this.productList = res.data.payload;
			})
			.catch(() => {
				
			})
    },
  }
}
</script>