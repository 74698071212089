<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="flex">
            <div class="left">
              <img src="./../img/heroText.svg" class="big-img"/>
              <div class="input-container input-container-filter">
                <div class="input-type">
                  <img src='./../img/imgIcon.svg' class="img"/>
                  <div class="desc">Images</div>
                </div>
                <input type="text" placeholder="Enter request" v-model="search" @input="toProductList"/>
                <div class="more-filters" @click="openFilter">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_96_715)">
                    <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 4V8" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 12V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 4V14" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 18V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 4V5" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 9V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_96_715">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
                <button class="button">
                  <img src='./../img/glass.svg' class="img"/>
                </button>
              </div>
            </div>
            <div class="right">
              <div class="title small">
                Step into the future
              </div>
              <div class="desc regular">
                of digital artistry with imaginaryit, where cutting-edge AI technology meets creativity to bring you stunning, unique images and videos. Our platform offers a vast array of AI-generated visuals, perfect for any project or inspiration.
              </div>
            </div>
          </div>
          <div class="mouse" @click="scrollToSection('#whatWeOffer')">
            <div class="desc">
              <b>What We Offer</b>
            </div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8C10 9.10457 10.8954 10 12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 16L18 8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8L6 16C6 19.3137 8.68629 22 12 22C15.3137 22 18 19.3137 18 16Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="section whatWeOffer-section" id="whatWeOffer">
        <div class="wrapper">
          <div class="main-img">
            <img src='./../img/whatWeOfferText.svg' class="img"/>
            <img src='./../img/whatWeOfferDecor.svg' class="decor"/>
          </div>
          <div class="list">
            <div class="item">
              <div class="title num">
                <b>01</b>
              </div>
              <div class="text">
                <div class="title small">
                  <b>Exclusive AI-Generated Images:</b>
                </div>
                <div class="desc regular">
                  Browse through our extensive collection of one-of-a-kind AI-created visuals, available for purchase and immediate download.
                </div>
              </div>
            </div>
            <div class="item">
              <div class="title num">
                <b>02</b>
              </div>
              <div class="text">
                <div class="title small">
                  <b>Inspiration and Innovation:</b>
                </div>
                <div class="desc regular">
                  Discover new ideas and trends in digital art with our regularly updated gallery of AI-generated masterpieces.
                </div>
              </div>
            </div>
            <div class="item">
              <div class="title num">
                <b>03</b>
              </div>
              <div class="text">
                <div class="title small">
                  <b>Seamless Shopping Experience:</b>
                </div>
                <div class="desc regular">
                  Seamless Shopping Experience: Enjoy a user-friendly interface that makes finding and purchasing the perfect image or video simple and straightforward.
                </div>
              </div>
            </div>
          </div>
          <div class="text-img">
            <img src='./../img/whatWeOfferText2.svg' class="img"/>
            <img src='./../img/whatWeOfferText2.svg' class="img"/>
          </div>
        </div>
      </div>  
      <div class="section images-section">
        <div class="wrapper">
          <div class="desc-container">
            <div class="desc desc-main">
              Whether you're looking for unique visuals to enhance your projects or seeking inspiration, imaginaryit delivers content that exceeds expectations.
            </div>
          </div>
          <img src='./../img/imagesDecor.svg' class="decor"/>
          <img src='./../img/imagesTitle.svg' class="imagesTitle"/>
          <div class="link-container">
            <router-link to="/product-list/All" class="desc link">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_464_68)">
                <path d="M15 8H15.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4 15L8 11C8.45606 10.5611 8.97339 10.3301 9.5 10.3301C10.0266 10.3301 10.5439 10.5611 11 11L16 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 14L15 13C15.4561 12.5611 15.9734 12.3301 16.5 12.3301C17.0266 12.3301 17.5439 12.5611 18 13L20 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_464_68">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <span>
                <b>All Images</b>
              </span>
            </router-link>
          </div>
          <div class="list">
            <div class="img-wrapper">
              <img src='./../img/images/1.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/images/2.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/images/3.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/images/4.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/images/5.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/images/6.png' class="img"/>
            </div>
          </div>
        </div>
      </div>
      <div class="section images-section videos-section">
        <div class="wrapper">
          <img src='./../img/videosTitle.svg' class="videosTitle"/>
          <div class="link-container">
            <router-link to="/video" class="desc link">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_541_76)">
                <path d="M17 5H7C4.79086 5 3 6.79086 3 9V15C3 17.2091 4.79086 19 7 19H17C19.2091 19 21 17.2091 21 15V9C21 6.79086 19.2091 5 17 5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 9L15 12L10 15V9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_541_76">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <span>
                <b>Video</b>
              </span>
            </router-link>
          </div>
          <div class="list">
            <div class="img-wrapper">
              <img src='./../img/videos/1.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/videos/2.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/videos/3.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/videos/4.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/videos/5.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/videos/6.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/videos/7.png' class="img"/>
            </div>
            <div class="img-wrapper">
              <img src='./../img/videos/8.png' class="img"/>
            </div>
          </div>
        </div>
      </div>
      <div class="section mission-section">
        <div class="wrapper">
          <div class="title">
            <b>Our mission</b>
          </div>
          <div class="flex">
            <img src='./../img/mission.svg' class="img"/>
            <div class="desc medium">
              At imaginaryit , we are dedicated to revolutionizing the way you experience art. Our mission is to provide high-quality, AI-created images and videos that inspire creativity and innovation, making it easy for anyone to access and utilize the power of artificial intelligence in their creative endeavors.
            </div>
          </div>
        </div>
      </div>
      <div class="section getStarted-section">
        <div class="wrapper">
          <img src='./../img/getStarted.svg' class="img"/>
          <div class="flex">
            <div class="desc medium">
              Explore the world of AI-generated art with imaginaryit. Browse our collection, request custom visuals, and find inspiration for your next project.
            </div>
            <router-link 
              v-if="$parent.isAuth" 
              to="'/product-list/All"
             class="button big"
            >
              Explore
            </router-link>
            <button 
              v-if="!$parent.isAuth" 
              class="button big"
              @click="$parent.openSignUpModal()"
            >
              Join us
            </button>
          </div>
        </div>
      </div>
      <div class="section proud-section">
        <div class="wrapper">
          <div class="title normal">
            <b>We Are Proud Of</b>
          </div>
          <div class="desc regular">
            At imaginaryit, we take immense pride in our ability to blend cutting-edge technology with artistic creativity to produce truly unique visuals.
          </div>
          <div class="list">
            <div class="item">
              <img src='./../img/proud/1.png' class="img"/>
              <div class="text">
                <img src='./../img/favorite.svg' class="icon"/>
                <div class="title small">
                  <b>Favorite</b>
                </div>
                <router-link 
                  to="/product-list/All"
                  class="desc desc-link"
                >
                  <span>
                    <b>More</b>
                  </span>
                </router-link>
              </div>
            </div>
            <div class="item">
              <img src='./../img/proud/2.png' class="img"/>
              <div class="text">
                <img src='./../img/mostPurchased.svg' class="icon"/>
                <div class="title small">
                  <b>Most Purchased</b>
                </div>
                <router-link 
                  to="/product-list/All"
                  class="desc desc-link"
                >
                  <span>
                    <b>More</b>
                  </span>
                </router-link>
              </div>
            </div>
            <div class="item">
              <img src='./../img/proud/3.png' class="img"/>
              <div class="text">
                <img src='./../img/newest.svg' class="icon"/>
                <div class="title small">
                  <b>Newest</b>
                </div>
                <router-link 
                  to="/product-list/All"
                  class="desc desc-link"
                >
                  <span>
                    <b>More</b>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Home',
  props: ['currency', 'categories'],
  components: {
  },
  data: function() {
    return {
      imgDomain: '',
      favoritesList: [],
      search: ''
    }
  },
  mounted() {
    this.getFavoritesList();
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    
  },
  methods: {
    openFilter() {
      if (this.categories.length) {
        this.$router.push({ path: '/product-list/' + this.categories[0].title.toLowerCase().replace(/\s+/g, '_') });
        this.$parent.openFilterModal();
      }
    },
    toProductList() {
      if (this.categories.length) {
        this.$router.push({ path: '/product-list/' + this.categories[0].title.toLowerCase().replace(/\s+/g, '_') });
      }
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    getFavoritesList() {
      this.favoritesList = [];

      this.$http.get(process.env.VUE_APP_API + 'products')
      .then((resp) => {
        this.favoritesList = resp.data.payload.filter(item => (item.product_type === 'image'));
      })
      .catch(() => {
        
      })
      
			
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openReadyModal() {
			this.$parent.openReadyModal();
			this.$parent.setReadyPack();
		},
  
  }
}
</script>