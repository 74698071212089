<template>
  <header class="header">
    <div class="wrapper">
      <div class="flex">
        <div class="flex-left">
          <router-link to="/" class="logo">
            <img src="./../img/logo.svg" class="img"/>
          </router-link>
        </div>
        <div class="flex-right">
          <router-link to="/product-list/All" class="desc">
            Images
          </router-link>
          <router-link to="/video" class="desc">
            Video
          </router-link>
          <div v-if="$route.name != 'Login' && $route.name != 'Register'" class="menu-icon" @click="$parent.toggleMenu" :class="{'active': $parent.menuVisible}">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_464_999)">
              <path d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5C18 5.55228 18.4477 6 19 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_464_999">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <div class="desc">Menu</div>
          </div>
          <!-- <Nav/> -->
          <div class="header-profile" v-if="$parent.isAuth">
            <div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                      @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
              </select>
            </div>
            <!-- <div class="balance-block">
              <div class="title small">{{ $parent.userBalance }} {{ $parent.currency }}</div>
            </div> -->
<!--            <router-link to="/cart" class="button cart">-->
<!--              <img class="img" src="./../images/cart.svg"/>-->
<!--              <div v-if="$parent.cartContents && $parent.cartContents.length" class="indicator">-->
<!--                {{ $parent.cartContents.length }}-->
<!--              </div>-->
<!--            </router-link>-->
            <div class="button cart topup-balance">
              <img class="img" src="./../img/top_up.svg"/>
              <div v-if="$parent.userBalance">
                €  {{ $parent.userBalance }}
              </div>
              <button class="button" @click.prevent="$parent.openTopUpModal()">
                Top Up
              </button>
            </div>
            <router-link class="profile-desc" to="/profile/my-orders">
              <div class="icon">
                <img class="img" src="./../img/user.svg"/>
              </div>
            </router-link>
          </div>
          <div v-else class="buttons">
            <div class="select-container" v-if="curr && ($parent.currencyOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                      @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
              </select>
            </div>
            <a class="button" @click="$emit('openSignInModal')">
              Sign In
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import Nav from '../components/Nav.vue' 

export default {
  name: 'Header',
  props: ['currencyCode', 'currencyValue', 'categories'],
  components: {
    // Nav
  },
  data: function () {
    return {
      curr: ''
    }
  },
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({code}) => code);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    selectCurr() {
      this.changeCurrency(this.curr)
      localStorage.setItem("currency", this.curr);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
  }
}
</script>
