<template>
  <main class="main page-inside login-page contacts-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="contact">
            <div class="left">
              <div class="title big">Contact us</div>
              <div class="title small">
                Need Additional Help?
              </div>
              <div class="info">
                  <a :href="item.url" target="_blank" class="social" v-for="(item, i) in $parent.socials" :key="i">
                    <div class="img-wrapper">
                      <img :src="$parent.imgDomain + item.image" class="img"/>
                    </div>
                    <div class="desc">{{ item.title }}</div>
                  </a>
              </div>
              <div class="info">
                <div class="desc"> {{ $parent.settings.requisites }}</div>
              </div>
            </div>
            <div class="right">
              <div class="right-wrapper">
                <div class="title small">Fill The Form</div>
                <div class="desc regular">And we eill contact you </div>
                <div class="content form-fields form-fields-wrapper">
                  <label>
                    <img src="./../img/user.svg" class="img"/>
                    <div class="desc">Name:</div>
                    <input type="text" placeholder="Name" v-model="name"/>
                  </label>
                  <label>
                    <img src="./../img/envelope.svg" class="img"/>
                    <div class="desc">Email:</div>
                    <input type="text" placeholder="Email" v-model="email"/>
                  </label>
                  <label class="textarea">
                    <img src="./../img/message.svg" class="img"/>
                    <div class="desc">Your message:</div>
                    <textarea placeholder="Message" v-model="message"></textarea>
                  </label>
                  <button class="button medium" @click="submit">Send</button>
                  <transition name="fade">
                    <div v-if="$parent.successMessage" class="desc small green">{{$parent.successMessage}}</div>
                  </transition>
                  <transition name="slide">
                    <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Faq',
  props: ['categories'],
  components: {
  },
  data: function() {
    return {
      faq: [],
      activePanels: [],
      name: '',
      email: '',
      message: ''
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
          this.faq = res.data.payload;
      })
      .catch(() => {
        
      })
  },
  methods: {
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>