<template>
  <div class="modal ready-modal">
    <div class="overlay"  @click="$parent.closeReadyModal"></div>
    <div class="wrapper">
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="modal-head">
                <div class="close" @click="$parent.closeReadyModal">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_168_1950)">
                    <path d="M18 6L6 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 6L18 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_168_1950">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="title small">Purchase product?</div>
              </div>
              <div class="buttons">
                <button class="button bordered" @click="$parent.closeReadyModal">
                  <span>Cancel</span>
                </button>
                <button :class="['button blue', {'disabled': $parent.addToCartBtnLoader}]" @click="addToCart">
                  <span>Yes</span>
                </button>
              </div>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  
  methods: {
		addToCart() {
			this.$emit('addToCart')
		}
  }
}
</script>