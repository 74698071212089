<template>
  <main class="main page-inside product-list-page video-page">
    <div class="main-wrapper">
      <div class="section hero-section product-list-section">
        <div class="breadcrumbs">
          <div class="desc">
            Home
          </div>
          <div class="desc">/</div>
          <div class="desc link">
            Video
          </div>
        </div>
        <div class="wrapper first">
          <div class="stroke-text">
            Video
          </div>
          <div class="left">
            <div class="input-container input-container-filter">
              <div class="input-type">
                <img src='./../img/video.svg' class="img"/>
                <div class="desc">Video</div>
              </div>
              <input type="text" placeholder="Enter request" @input="filterProducts" v-model="search"/>
              <div class="more-filters" @click="$parent.openFilterModal()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_96_715)">
                  <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 4V8" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 12V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 4V14" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 18V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 4V5" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 9V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_96_715">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              <button class="button blue">
                <img src='./../img/glass.svg' class="img"/>
              </button>
            </div>
          </div>
          <div class="right">
            <div class="select-container">
              <select v-model="sort">
                <option value="desc">Newest</option>
                <option value="asc">Oldest</option>
              </select>
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div :class="['text-container', {'active': videoPageLoaded}]">
            <div class="title">Launching soon at imaginaryit</div>
          </div>
          <div class="desc medium">
            <span>Stay tuned for exciting new features and content.</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Video',
  props: ['categories'],
  components: {
  },
  data: function() {
    return {
      sort: 'desc',
      videoPageLoaded: false
    }
  },
  methods: {
    
  },
  mounted: function() {
    let self = this;
    setTimeout(() => {
      self.videoPageLoaded = true;
    }, 300);
  }
}
</script>