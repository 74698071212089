<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="breadcrumbs" v-if="productPageItem">
            <div v-if="typeOptions && productPageItem.product_type == 'doc'" class="desc link" @click="$emit('chooseType', typeOptions[1])">
              {{ typeOptions[1].title }}
            </div>
            <div v-if="typeOptions && productPageItem.product_type == 'image'" class="desc link" @click="$emit('chooseType', typeOptions[0])">
              {{ typeOptions[0].title }}
            </div>
            <div class="desc">→</div>
            <div @click="goToTagProducts" class="desc link">{{ this.tag }}</div>
            <div class="desc">→</div>
            <div class="desc">{{ productPageItem.title }}</div>
          </div>
          <transition name="fade">
            <div class="product-preview" v-if="productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <img v-if="productPageItem.product_type == 'image'" :src="$parent.imgDomain + productPageItem.doc_url" class="img"/>
                    <div v-else class="doc-img">
                      <img src='./../img/doc.svg' class="img"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text">
                <div class="text-wrapper">
                  <div class="top">
                    <div class="row">
                      <div class="desc">Name</div>
                      <div class="title">{{productPageItem.title}}</div>
                    </div>
                    <div class="row">
                      <div class="desc">Description</div>
                      <div class="desc" v-html="productPageItem.description"></div>
                    </div>
                    <div class="row tags-row">
                      <div class="desc">Keywords</div>
                      <div class="tags">
                        <router-link v-for="tag in productPageItem.tags" :key="tag.id" :to="productPageItem.product_type === 'doc' ? `/product-list/texts?filter[tag]=${tag.name}` : `/product-list/images?filter[tag]=${tag.name}` " class="button tags__item">
<!--                        <div v-for="tag in productPageItem.tags" class="button tags__item" :key="tag.id">-->
                          {{ tag.name }}
<!--                        </div>-->
                        </router-link>
                      </div>
                    </div>
                    <div class="row row-price">
                      <div class="desc">Price:</div>
                      <div class="title">
                        {{productPageItem.price}}
                        <span class="currency">{{currency}}</span>
                      </div>
                    </div>
                  </div>
                  <button class="button blue" @click="openReadyModal(productPageItem)">
                      Purchase product
                    </button>
                  <div class="desc">This is only a preview and not a real representation of the picture. After purchasing the product, you will be able to download it in the desired quality, format and without watermarks</div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
export default {
  name: 'ProductPage',
  props: ['currency', 'productPageItem'],
  components: {
  },
  data: function() {
    return {
      tag: '',
      typeOptions: ''
    }
  },
	watch: {
    currency: function(newVal) {
      console.log(newVal)
      const id = this.$route.params.id;
      this.$emit('getProductPageContent', id);
    },
    categories: function(newVal) {
      let tag = newVal.find(cat => cat.id ==  this.productPageItem.category_id);
			this.tag = tag.title;
    },
    productPageItem: function(newVal) {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        let categories = res.data.payload;
        let tag;
        tag = categories.find(cat => cat.id ==  newVal.category_id);
        this.tag = tag.title;
        
      })
      .catch(() => {
        
      })
    }
	},
  created() {
    
  },
  computed: {
    keywords() {
      return this.productPageItem.keywords.split(",");
    },
  },
  
  mounted() {
    this.$emit('getProductPageContent',this.$route.params.id);
    this.$http.get(process.env.VUE_APP_API + 'categories')
		.then((res) => {
			let categories = res.data.payload;
			let tag = categories.find(cat => cat.id ==  this.productPageItem.category_id);
			this.tag = tag.title;
		})
		.catch(() => {
			
		})
    this.$http.get(process.env.VUE_APP_API + 'categories')
    .then((res) => {
      this.typeOptions = res.data.payload.filter(item => item.parent_id === null);
    })
    .catch(() => {
        
    })
  },
  methods: {
    openReadyModal(item) {
			this.$emit('openReadyModal', item)
		},
    goToTagProducts() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        let categories = res.data.payload;
        let tag = categories.find(cat => cat.title ==  this.tag);
        this.$router.push({path: '/product-list/' + (this.productPageItem.product_type == 'doc' ? 'texts' : 'images') + '/?filter%5Bcategory%5D=' + tag.id});
      })
      .catch(() => {
        
      })
    }
  }
}
</script>