<template>
  <div class="modal ready-modal">
    <div class="overlay"  @click="$parent.closeDeactivateModal"></div>
    <div class="wrapper">
      <div class="close" @click="$parent.closeDeactivateModal">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_168_1950)">
          <path d="M18 6L6 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_168_1950">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title small">Are you sure you want to deactivate this product?<br/>
                This product will not be available for purchase by other users</div>
              <div class="buttons">
                <button class="button" @click="$parent.closeDeactivateModal">
                  <span>Cancel</span>
                </button>
                <button class="button blue" @click="deactivate">
                  <span>Deactivate</span>
                </button>
              </div>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeactivateModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  
  methods: {
		deactivate() {
			this.$http.post(process.env.VUE_APP_API + 'user/media/' + this.$parent.itemToDeactivate + '/delete')
      .then((res) => {
        if (res.data.status == "OK") {
          this.$parent.getCreatedHistory();
          this.$parent.closeDeactivateModal();
        } else {
          this.$parent.closeDeactivateModal();
          this.$emit('setNewError', res.data.message)
        }
      })
      .catch((res) => {
        this.$parent.closeDeactivateModal();
        this.$emit('setNewError', res.response.data.message)
      })
		}
  }
}
</script>