<template>
	<footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="./../img/logo.svg" class="img"/>
          </router-link>
          <div class="desc">
            {{ $parent.settings.requisites }}
            <br/><br/>
            {{ $parent.settings.copyright }}
          </div>
        </div>
        <div class="right">
          <div class="column">
            <ul class="nav" v-if="$parent.textPageList.length">
              <template  v-for="item in $parent.textPageList">
                <li class="nav__item" :key="item.id">
                  <div @click="$parent.goToPage(item.id)" class="desc">
                    {{item.title}}
                  </div>
                </li>
              </template>
            </ul>
          </div>
          <div class="column">
            <ul class="nav">
              <li class="nav__item">
                <router-link 
                  to="/faq" 
                  class="desc" 
                  v-text="'FAQ'"
                />
              </li>
              <li class="nav__item">
                <router-link 
                  to="/contacts" 
                  class="desc" 
                  v-text="'CONTACT US'"   
                />
              </li>
            </ul>
            <ul class="methods">
              <li class="methods__item">
                <img src='./../img/methods/mc.svg' class="img"/>
              </li>
              <li class="methods__item">
                <img src='./../img/methods/visa.svg' class="img"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
     
		}
	},
	methods: {
		scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 1)
      this.$emit('setActiveModel', model);
    }
	},
	mounted() {
    
  }
}
</script>
