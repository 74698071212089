<template>
  <div class="modal product-modal">
    <div class="overlay"  @click="$parent.closeProductModal"></div>
    <div class="wrapper">
      <div class="container">
        <transition name="fade">
          <div class="product-preview" v-if="productPageItem">
            <div class="modal-head">
              <div class="close" @click="$parent.closeProductModal">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_168_1950)">
                  <path d="M18 6L6 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L18 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_168_1950">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div class="preview-container">
              <div class="preview-wrapper">
                <div class="preview">
                  <img v-if="productPageItem.product_type == 'image'" :src="$parent.imgDomain + productPageItem.doc_url" class="img"/>
                  <div v-else class="doc-img">
                    <img src='./../img/doc.svg' class="img"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="text">
              <div class="text-wrapper">
                <div class="top">
                  <div class="title small">{{productPageItem.title}}</div>
                  <div class="row tags-row">
                    <div class="tags">
                      <!-- <router-link v-for="tag in productPageItem.tags" :key="tag.id" :to="productPageItem.product_type === 'doc' ? `/product-list/texts?filter[tag]=${tag.name}` : `/product-list/images?filter[tag]=${tag.name}` " class="button tags__item"> -->
                        <div v-for="tag in productPageItem.tags" class="button tags__item" :key="tag.id">
                        {{ tag.name }}
                        </div>
                      <!-- </router-link> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="desc regular">
                      <b>Description</b>
                    </div>
                    <div class="desc regular" v-html="productPageItem.description"></div>
                  </div>
                </div>
                <div class="price-container">
                  <transition name="fade">
                    <span class="desc green" v-if="addToCartChosenItem == productPageItem.id && !error">Item successfully added to your cart</span>
                  </transition>
                  <transition name="fade">
                    <span class="desc red" v-if="addToCartChosenItem == productPageItem.id && error">{{ error }}</span>
                  </transition>
                  <div class="price title">
                    <span class="price-desc">{{productPageItem.price}} <span class="currency">{{currency}}</span></span>
                    <button class="button" @click="$parent.closeProductModal(); openReadyModal(productPageItem)">Purchase</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductModal',
  props: ['productPageItem', 'currency', 'addToCartChosenItem', 'error'],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {
    openReadyModal(item) {
      this.$emit('openReadyModal', item)
    },
  }
}
</script>