<template>
  <main class="main login-page">
    <div class="main-wrapper">
      <div class="container">
        <img src="./../img/whatWeOfferDecor.svg" class="decor"/>
        <router-link to="/" class="logo">
          <img src="./../img/logo.svg" class="img"/>
        </router-link>
        <div class="center">
          <img src="./../img/welcome.svg" class="welcome"/>
          <div class="title small">Forgot password ?</div>
          <div class="form">
            <div class="form-wrapper">
              <div class="form-fields forgot-pass-content">
                <div class="form-fields-wrapper">
                  <label>
                    <img src="./../img/envelope.svg" class="img"/>
                    <div class="desc">Email</div>
                    <input type="email" placeholder="Email" v-model="email"/>
                  </label>
                  <div class="cta-container">
                    <button class="button medium" @click="submitForgotPass">
                      Recover
                    </button>
                  </div>
                  <transition name="fade">
                    <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                  </transition>
                  <transition name="slide">
                    <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                  </transition>
                  <router-link to="/register" class="modal-bottom">
                    <div class="desc">
                      <b>Don’t have an account?</b>
                    </div>
                    <div class="desc">
                      <span>Sign up!</span>
                    </div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_541_560)">
                      <path d="M3 12H6" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 3V6" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.79961 7.79961L5.59961 5.59961" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.1992 7.79961L18.3992 5.59961" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.79961 16.2002L5.59961 18.4002" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 12L21 15L17 17L15 21L12 12Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_541_560">
                      <rect width="24" height="24" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
  
  <script>
  
  export default {
    name: 'Recover',
    props: [],
    components: {
    },
    data: function() {
      return {
        email: ''
      }
    },
    mounted() {
      
    },
    methods: {
      submitForgotPass() {
        let data = {
          "email": this.email
        }
        this.$emit('forgotPass', data)
      }
    }
  }
  </script>