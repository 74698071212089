<template>
  <div class="modal filter-modal">
    <div class="overlay"  @click="$parent.closeFilterModal"></div>
    <div class="wrapper">
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="modal-head">
                <div class="close" @click="$parent.closeFilterModal">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_168_1950)">
                    <path d="M18 6L6 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 6L18 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_168_1950">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="title small">Filters</div>
              </div>
              <div class="bottom">
                <!-- <div class="filter-tabs">
                  <div @click="filterTab = 'categories'" :class="{'blue active': filterTab === 'categories'}" class="button">Categories</div>
                  <div @click="filterTab = 'tags'" :class="{'blue active': filterTab === 'tags'}" class="button">Tags</div>
                </div> -->
                <div class="input-container input-container-filter">
                  <div class="more-filters">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_96_715)">
                      <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 4V8" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 12V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 4V14" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 18V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18 4V5" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18 9V20" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_96_715">
                      <rect width="24" height="24" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <input type="text" placeholder="Enter request" v-model="search"/>
                  <button class="button blue" @click="filter">
                    <img src='./../img/glass.svg' class="img"/>
                  </button>
                </div>
                <template v-if="filterTab === 'categories'" >
                  <div v-if="activeCategories && activeCategories.length > 0" class="active-list">
                    <button v-for="item in activeCategories" @click="removeFilter('category', item)" class="button blue"
                            :key="item.id">
                      {{ item.title }}
                      <img src='./../img/close.svg' class="img"/>
                    </button>
                  </div>
                  <div class="list" v-if="$parent.appActiveType == 'Texts'">
                    <button v-for="item in filteredDocCategories" @click="addFilter('category', item)" class="button" :key="item.id">
                      {{ item.title }}
                    </button>
                  </div>
                  <div class="list" v-else>
                    <button v-for="item in filteredImgCategories" @click="addFilter('category', item)" class="button" :key="item.id">
                      {{ item.title }}
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div v-if="activeTags && activeTags.length > 0" class="active-list">
                    <button v-for="item in activeTags" @click="removeFilter('tag', item)" class="button blue"
                            :key="item.id">
                      {{ item.name }}
                      <img src='./../img/close.svg' class="img"/>
                    </button>
                  </div>
                  <div class="list">
                    <div class="desc regular list-desc">
                      <b>All filters:</b>
                    </div>
                    <button v-for="item in filteredTags" @click="addFilter('tag', item)" class="button" :key="item.id">
                      {{ item.name }}
                    </button>
                  </div>
                </template>
              </div>
              <div class="buttons">
                <button class="button bordered" @click="$parent.closeFilterModal">
                  <span>Cancel</span>
                </button>
                <button class="button" @click="applyFilters">
                  <span>Apply</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      filterTab: 'tags',
      activeCategories: [],
      categoryOptions: [],
      search: '',
      filteredCategories: [],
      filteredDocCategories: [],
      filteredImgCategories: [],
      activeTags: [],
      tagOptions: [],
      filteredTags: []
    }
  },
  mounted() {
    this.getCategories();
    this.getTags();
  },
  methods: {
    filter() {
      const searchTerm = this.search.toLowerCase();

      if (this.filterTab === 'categories') {
        this.filteredCategories = this.categoryOptions.filter(item => item.title.toLowerCase().includes(searchTerm));
      } else {
        this.filteredTags = this.tagOptions.filter(item => item.name.toLowerCase().includes(searchTerm));
      }
    },
    addFilter (type, item) {
      let filters;
      let itemKeyField;

      if (type === 'category') {
        filters = this.activeCategories;
        itemKeyField = 'title';
      } else {
        filters = this.activeTags;
        itemKeyField = 'name';
      }

      const itemToAdd = item
      const exists = filters.find(item => item[itemKeyField] === itemToAdd[itemKeyField]);
      if (!exists) {
        filters.push(item);
      }
    },
    removeFilter (type, item) {
      let filters;
      let itemKeyField;

      if (type === 'category') {
        filters = this.activeCategories;
        itemKeyField = 'title';
      } else {
        filters = this.activeTags;
        itemKeyField = 'name';
      }

      const itemToRemove = item;
      const indexToRemove = filters.findIndex(item => item[itemKeyField] === itemToRemove[itemKeyField]);

      if (indexToRemove !== -1) {
        filters.splice(indexToRemove, 1);
      }
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;
        let types = res.data.payload.filter(item => item.parent_id === null);
        if (this.$parent.appActiveType === 'Texts') {
          this.filteredDocCategories = this.categoryOptions.filter(item => item.parent_id === types[1].id);
        } else {
          this.filteredImgCategories = this.categoryOptions.filter(item => item.parent_id === types[0].id);
        }
        
        if (this.$route.query['filter[category]']) {
          const categoryIds = this.$route.query['filter[category]'].split(',').map((id) => parseInt(id));
          this.activeCategories = this.categoryOptions.filter(({id}) => categoryIds.includes(id));
        }
      })
      .catch(() => {
         
      })
    },
    getTags() {
      this.$http.get(process.env.VUE_APP_API + 'tags')
          .then((res) => {
            this.tagOptions = res.data.payload;
            this.filteredTags = this.tagOptions;

            if (this.$route.query['filter[tag]']) {
              const tags = this.$route.query['filter[tag]'].split(',');
              this.activeTags = this.tagOptions.filter(({name}) => tags.includes(name));
            }
          })
          .catch(() => {

          })
    },
    updateUrlFilters() {
      const currentPath = this.$route.path;
      const currentQuery = this.$route.query;
      const newQuery = { ...currentQuery, 'filter[tag]': this.activeTags.map(({name}) => name).join(',') };
      this.$router.replace({ path: currentPath, query: newQuery });
    },
    applyFilters() {
      this.$emit('applyFilters', this.activeCategories, this.activeTags);
      this.updateUrlFilters();
      this.$parent.closeFilterModal();
    }
  }
}
</script>