<template>
  <div class="modal withdraw-modal success-create-modal">
    <div class="overlay"  @click="$parent.closeNoDealModal"></div>
    <div class="wrapper">
      <div class="container">
        <div class="modal-head">
          <div class="close" @click="$parent.closeNoDealModal">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_168_1950)">
              <path d="M18 6L6 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 6L18 18" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_168_1950">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
          <div class="title">Deal is declined</div>
        </div>
        <div class="modal-body">
          <div class="desc">
            Try again or contact us
          </div>
          <button class="button" @click="$parent.closeNoDealModal">
            <span>OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NoDealModal',
  props: [],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {

  }
}
</script>